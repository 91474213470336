// ChatMessage.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import TextToSpeechButton from "./TextToSpeechButton";
import Cookies from "js-cookie";
import { getChatID } from "./chatIDModule";
import { Tooltip } from "react-tooltip";
import ChatBoxLoader from "./ChatBoxLoader";

const ChatMessage = ({
  message,
  isLastMessage,
  lastUserMessage,
  audioOn,
  selectLanguage,
  onDataFromChild,
  handleIsWriting,
  loader,
}) => {
  const [userAgreed, setUserAgreed] = useState(false);
  const [userDisagreed, setUserDisagreed] = useState(false);

  const handleAgree = async () => {
    setUserAgreed(true);
    setUserDisagreed(false);

    sendFeedback(lastUserMessage.text, 1);
  };

  const handleDisagree = async () => {
    setUserDisagreed(true);
    setUserAgreed(false);

    sendFeedback(lastUserMessage.text, 0);
  };
  const sendFeedback = async (text, binaire) => {
    try {
      await axios.post(
        "https://cbmr-mfeedback.azurewebsites.net/api/Feedback?code=KvV3PM6hTqJwxPLVu34-3oa5oj2TPsTZfGVNPl9_tkXEAzFu6mJ-2Q==",
        {
          chat_response: text,
          feedback: binaire,
          ChatID: "TempFromCBUI",
          auth_key: "q8olbmjjOmONSx'Qvu04rFjNKRP^%CRr+ELaluZnZEnH%]ca+w",
        }
      );
    } catch (error) {
      console.error("Erreur lors de la récupération du feedback :", error);
      // Gérer les erreurs d'API
    }
  };
  const [agreeButtonText, setAgreeButtonText] = useState("I Agree");
  const [disagreeButtonText, setDisagreeButtonText] = useState("I Disagree");

  useEffect(() => {
    // Mettez en place une logique pour changer le texte des boutons en fonction de la langue sélectionnée
    switch (selectLanguage) {
      case "en":
        setAgreeButtonText("I Agree");
        setDisagreeButtonText("I Disagree");

        break;
      case "de":
        setAgreeButtonText("Ich stimme zu");
        setDisagreeButtonText("Ich stimme nicht zu");

        break;
      case "nl":
        setAgreeButtonText("Ik ga akkoord");
        setDisagreeButtonText("Ik ga niet akkoord");

        break;
      case "fr":
        setAgreeButtonText("Je suis d'accord");
        setDisagreeButtonText("Je ne suis pas d'accord");

        break;
      default:
        setAgreeButtonText("Je suis d'accord"); // La langue par défaut
        setDisagreeButtonText("Je ne suis pas d'accord"); // La langue par défaut
    }
  }, [selectLanguage]);

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(loader);
  const [streamingComplete, setStreamingComplete] = useState(false);

  useEffect(() => {
    const fetchData = async (response) => {
      try {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            break;
          }

          const decodedChunk = decoder.decode(value, { stream: true });
          if (
            message.isSuggestion &&
            lastUserMessage.text !== "Dis m'en plus..." &&
            lastUserMessage.text !== "Tell me more..." &&
            lastUserMessage.text !== "Vertel me meer..." &&
            lastUserMessage.text !== "Erzähl mir mehr..."
          ) {
            try {
              handleIsWriting(true);
              // Supprimez les guillemets ("") de la réponse
              const suggestionContent = JSON.parse(
                decodedChunk
              ).AI_response.replace(/"/g, "");

              // Divisez la suggestion en morceaux (ici, nous utilisons des mots)
              const suggestionWords = suggestionContent.split(" ");
              await Promise.all(
                suggestionWords.map((word, index) => {
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      setData((prevValue) => `${prevValue}${word} `);
                      resolve();
                    }, index * 100);
                  });
                })
              );
            } catch (e) {
              console.error("Failed to parse JSON:", e);
              // Handle errors of parsing JSON
            } finally {
              handleIsWriting(false);
            }
          } else {
            const jsonMatch = [
              ...decodedChunk.matchAll(
                /data: ({.*?})(?=\s*event: data|\s*$)/gs
              ),
            ];

            jsonMatch.forEach((match) => {
              // console.log(match)
              const jsonStr = match[1].trim();

              try {
                const jsonData = JSON.parse(jsonStr);
                // console.log(jsonData)

                if ("content" in jsonData) {
                  setData((prevValue) => `${prevValue}${jsonData.content}`);
                  // Continue reading the next chunk
                }
              } catch (e) {
                console.error("Failed to parse JSON:", e);
              }
            });
          }
        }
        handleIsWriting(loader);
        setStreamingComplete(true);
      } catch (error) {
        setLoading(false);
        // Handle errors during streaming
      }
    };
    if (!message.isUser && typeof message.text === "string") {
      setData(message.text);
    } else if (!message.isUser) {
      fetchData(message.text);
    }
    setLoading(loader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, message.text]);

  useEffect(() => {
    if (!loading) {
      onDataFromChild(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="text-left mx-auto w-11/12">
      <div
        className={`mx-2 my-2 ${
          isLastMessage ? "" : "border-b-2"
        } border-[#D0D3DB] relative w-full`}
      >
        {message.isUser ? (
          <img
            src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/user-3296+1.png"
            alt="Utilisateur"
            className="mx-1 my-2 absolute top-0 -left-5"
            style={{ width: "30px", height: "30px" }}
          />
        ) : (
          <img
            src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/picto-envoyer.png"
            alt=""
            className="mx-1 my-2 absolute top-0 -left-5"
            style={{ width: "30px", height: "30px" }}
          />
        )}
        <div className="flex-col py-2 pl-6 mb-5 w-full">
          <div className="flex w-full">
            <span className="text-base font-bold block align-middle whitespace-nowrap">
              {message.isUser ? "Vous" : "Victor IA"}
            </span>
            {message.isUser && isLastMessage
              ? ""
              : isLastMessage &&
                streamingComplete && (
                  <TextToSpeechButton
                    textToSpeak={data}
                    isLastMessage
                    audioOn={audioOn}
                    selectLanguage={selectLanguage}
                  />
                )}
          </div>
          <span
            className="text-justify max-w-prose text-sm sm:text-base font-medium "
            style={{ whiteSpace: "pre-wrap" }}
          >
            {message.isUser ? message.text : data}
          </span>
          {!message.isUser && isLastMessage && streamingComplete && (
            <div className="mt-2 text-white flex sm:block">
              {userAgreed || userDisagreed ? (
                <>
                  {userAgreed ? (
                    <span className="mr-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 71 71"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M41.4167 26.625V14.7917C41.4167 12.4379 40.4816 10.1805 38.8172 8.51611C37.1528 6.85173 34.8955 5.91669 32.5417 5.91669L20.7083 32.5417V65.0834H54.0783C55.5052 65.0995 56.8899 64.5994 57.9771 63.6752C59.0644 62.751 59.781 61.465 59.995 60.0542L64.0775 33.4292C64.2062 32.5812 64.149 31.7154 63.9099 30.8917C63.6707 30.068 63.2554 29.3061 62.6926 28.6589C62.1298 28.0117 61.433 27.4946 60.6505 27.1434C59.868 26.7922 59.0185 26.6153 58.1608 26.625H41.4167ZM20.7083 65.0834H11.8333C10.2641 65.0834 8.7592 64.46 7.64961 63.3504C6.54002 62.2408 5.91666 60.7359 5.91666 59.1667V38.4584C5.91666 36.8892 6.54002 35.3842 7.64961 34.2746C8.7592 33.1651 10.2641 32.5417 11.8333 32.5417H20.7083"
                          fill="#85A600"
                          fill-opacity="0.5"
                        />
                        <path
                          d="M20.7083 32.5417L32.5417 5.91669C34.8955 5.91669 37.1528 6.85173 38.8172 8.51611C40.4816 10.1805 41.4167 12.4379 41.4167 14.7917V26.625H58.1608C59.0185 26.6153 59.868 26.7922 60.6505 27.1434C61.433 27.4946 62.1298 28.0117 62.6926 28.6589C63.2554 29.3061 63.6707 30.068 63.9099 30.8917C64.149 31.7154 64.2062 32.5812 64.0775 33.4292L59.995 60.0542C59.781 61.465 59.0644 62.751 57.9771 63.6752C56.8899 64.5994 55.5052 65.0995 54.0783 65.0834H20.7083M20.7083 32.5417V65.0834M20.7083 32.5417H11.8333C10.2641 32.5417 8.7592 33.1651 7.64961 34.2746C6.54002 35.3842 5.91666 36.8892 5.91666 38.4584V59.1667C5.91666 60.7359 6.54002 62.2408 7.64961 63.3504C8.7592 64.46 10.2641 65.0834 11.8333 65.0834H20.7083"
                          stroke="#85A600"
                          stroke-width="5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span className="">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 71 71"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.5833 44.375V56.2083C29.5833 58.5621 30.5184 60.8195 32.1828 62.4839C33.8471 64.1483 36.1045 65.0833 38.4583 65.0833L50.2917 38.4583V5.91664H16.9217C15.4948 5.90051 14.1101 6.40061 13.0229 7.32479C11.9356 8.24897 11.2189 9.53497 11.005 10.9458L6.92249 37.5708C6.79378 38.4188 6.85098 39.2846 7.09011 40.1083C7.32925 40.932 7.74461 41.6939 8.30741 42.3411C8.87022 42.9883 9.56701 43.5054 10.3495 43.8566C11.132 44.2078 11.9815 44.3847 12.8392 44.375H29.5833ZM50.2917 5.91664H59.1667C60.7359 5.91664 62.2408 6.54 63.3504 7.6496C64.46 8.75919 65.0833 10.2641 65.0833 11.8333V32.5416C65.0833 34.1108 64.46 35.6158 63.3504 36.7254C62.2408 37.8349 60.7359 38.4583 59.1667 38.4583H50.2917"
                          fill="#8B8B8B"
                          fill-opacity="0.5"
                        />
                        <path
                          d="M50.2917 38.4583L38.4583 65.0833C36.1045 65.0833 33.8471 64.1483 32.1828 62.4839C30.5184 60.8195 29.5833 58.5621 29.5833 56.2083V44.375H12.8392C11.9815 44.3847 11.132 44.2078 10.3495 43.8566C9.56701 43.5054 8.87022 42.9883 8.30741 42.3411C7.74461 41.6939 7.32925 40.932 7.09011 40.1083C6.85097 39.2846 6.79378 38.4188 6.92249 37.5708L11.005 10.9458C11.2189 9.53496 11.9356 8.24897 13.0229 7.32479C14.1101 6.40061 15.4948 5.90051 16.9217 5.91664H50.2917M50.2917 38.4583V5.91664M50.2917 38.4583H59.1667C60.7359 38.4583 62.2408 37.8349 63.3504 36.7254C64.46 35.6158 65.0833 34.1108 65.0833 32.5416V11.8333C65.0833 10.2641 64.46 8.75919 63.3504 7.6496C62.2408 6.54001 60.7359 5.91664 59.1667 5.91664H50.2917"
                          stroke="#8B8B8B"
                          stroke-width="5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  )}
                </>
              ) : (
                <div className="rounded-lg w-min flex">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={"Je suis d'accord"}
                  >
                    <button onClick={() => handleAgree()} className="mr-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 97 97"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33.7084 45.5417L45.5417 18.9167C47.8955 18.9167 50.1529 19.8517 51.8173 21.5161C53.4816 23.1805 54.4167 25.4379 54.4167 27.7917V39.625H71.1609C72.0185 39.6153 72.868 39.7922 73.6505 40.1434C74.433 40.4946 75.1298 41.0117 75.6926 41.6589C76.2554 42.3061 76.6708 43.068 76.9099 43.8917C77.149 44.7154 77.2062 45.5812 77.0775 46.4292L72.995 73.0542C72.7811 74.465 72.0644 75.751 70.9772 76.6752C69.8899 77.5994 68.5052 78.0995 67.0784 78.0834H33.7084M33.7084 45.5417V78.0834M33.7084 45.5417H24.8334C23.2642 45.5417 21.7592 46.1651 20.6496 47.2746C19.54 48.3842 18.9167 49.8892 18.9167 51.4584V72.1667C18.9167 73.7359 19.54 75.2408 20.6496 76.3504C21.7592 77.46 23.2642 78.0834 24.8334 78.0834H33.7084"
                          stroke="#002EFF"
                          stroke-width="5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </a>
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={"Je ne suis pas d'accord"}
                  >
                    <button onClick={() => handleDisagree()}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 97 97"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M63.2916 51.4583L51.4583 78.0833C49.1045 78.0833 46.8471 77.1483 45.1827 75.4839C43.5184 73.8195 42.5833 71.5621 42.5833 69.2083V57.375H25.8391C24.9815 57.3847 24.132 57.2078 23.3495 56.8566C22.567 56.5054 21.8702 55.9883 21.3074 55.3411C20.7446 54.6939 20.3292 53.932 20.0901 53.1083C19.851 52.2846 19.7938 51.4188 19.9225 50.5708L24.005 23.9458C24.2189 22.535 24.9356 21.249 26.0228 20.3248C27.1101 19.4006 28.4948 18.9005 29.9216 18.9166L63.2916 18.9166M63.2916 51.4583L63.2916 18.9166M63.2916 51.4583H72.1666C73.7358 51.4583 75.2408 50.8349 76.3504 49.7254C77.46 48.6158 78.0833 47.1108 78.0833 45.5416V24.8333C78.0833 23.2641 77.46 21.7592 76.3504 20.6496C75.2408 19.54 73.7358 18.9166 72.1666 18.9166H63.2916"
                          stroke="#002EFF"
                          stroke-width="5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Tooltip
        id="my-tooltip"
        style={{
          backgroundColor: "#002EFF",
          color: "#fff",
          borderRadius: "5px",
        }}
      />
    </div>
  );
};

export default ChatMessage;
